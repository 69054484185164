import React from "react";
import {
  SEO,
  PageLayout,
  OutboundLink,
  Background,
  FoodMenu,
} from "@bluefin/components";
import { Grid, Header, Dropdown } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class MenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessMenuModifierSet,
      allFishermanBusinessMenuModifier,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"}>
              <Header as={"h1"}>Menu</Header>
              <Dropdown
                button={true}
                text={"Order Online"}
                className={"order-online-dropdown-cta"}
              >
                <Dropdown.Menu primary={true}>
                  <Dropdown.Item>
                    <OutboundLink
                      to={
                        "https://www.toasttab.com/antonios-house-of-pizza-kirkman-4626-s-kirkman-rd"
                      }
                      label={"Orlando"}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <OutboundLink
                      to={
                        "https://www.toasttab.com/antonios-house-of-pizza-kissimmee-2362-east-irlo-bronson-memorial-highway-ste-a103"
                      }
                      label={"Kissimmee"}
                    />
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <OutboundLink
                      to={
                        "https://www.toasttab.com/antonios-house-of-pizza-apopka-1097-w-orange-blossom-trail"
                      }
                      label={"Apopka"}
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column
              width={"16"}
              textAlign={"left"}
              className={"menu-pattern-container"}
            >
              <Background columns={1}>
                <Background.Pattern type={"CIRCLE"}>
                  <FoodMenu
                    header={""}
                    centerNavigation={true}
                    showMenuLikes={true}
                    disableScheduleAvailability={true}
                    cart={false}
                    categoryDisplayVariant={"CategoryTwoSides"}
                    itemDisplayVariant={"LeftImageHorizontalCard"}
                    itemCartModalVariant={"CatalogItemLightbox"}
                    menu={{
                      schedules: allFishermanBusinessMenuSchedule.nodes,
                      categories: allFishermanBusinessMenuCategory.nodes,
                      items: allFishermanBusinessMenuItem.nodes,
                      modifierSets: allFishermanBusinessMenuModifierSet.nodes,
                      modifiers: allFishermanBusinessMenuModifier.nodes,
                    }}
                    business={{
                      id: fishermanBusiness._id,
                      type: fishermanBusiness.type,
                    }}
                  />
                </Background.Pattern>
              </Background>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Menu" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
    allFishermanBusinessMenuModifierSet {
      nodes {
        _id
        minAllowed
        modifiers
        name
      }
    }
    allFishermanBusinessMenuModifier {
      nodes {
        _id
        name
        price
      }
    }
  }
`;
